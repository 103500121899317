<template>
    <div class="full-page bg-primary of-h" title="">
        <div class="card bs-5 w-30r bg-1">
            <div class="text-center">
                <div class="fl-x fl-j-c">
                    <img src="../../../assets/img/logo/logo-1.png" class="filter-invert logo-img mb-4 px-5" alt="">
                </div>
                <h2 class="text-primary"> Login</h2>
                <p class="text-primary">Login with your credentials</p>
            </div>

            <s-form ref="loginForm">
                <validated-input icon="fa fa-user" name="Username" label="Username" v-model="model.username"
                                 :disabled="loading" :rules="rules.username"/>
                <validated-input icon="fa fa-lock" name="Password" label="Password" type="password"
                                 v-model="model.password"
                                 :disabled="loading" :rules="rules.password"/>

                <div class="card bg-danger mb-2" v-if="errorMessage" v-html="errorMessage"></div>

                <btn @click="submitClicked" icon="fa fa-lock" text="Login" loading-text="Validating..." size="block"
                     :disabled="loading" :loading="loading"/>
            </s-form>
        </div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import { mapActions } from 'vuex';
import axios from 'secure-axios';

export default {
    name: 'Login',
    data () {
        return {
            loginUrl: urls.auth.login,
            loading: false,
            model: {},
            errorMessage: '',
            rules: {
                username: {
                    required: true
                },
                password: {
                    required: true
                }
            }
        };
    },
    methods: {
        ...mapActions(['setUser']),
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.login, that.model);
            const json = response.data;
            if (!json) {
                this.loginError();
            } else if (json.error === false) {
                this.loginClicked(json);
                if (json.user.is_superuser === false) {
                    this.loginError();
                }
            } else {
                this.loginError();
            }
            that.loading = false;
        },
        formError (json) {
            this.$refs.loginForm.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        },
        loginClicked (response) {
            if (response.user && response.user.is_superuser) {
                const redirectUrl = sessionStorage.getItem('redirectPath');
                this.setUser({
                    ...response.user
                });
                this.$router.push(redirectUrl || { path: '/admin/' });
            }
            localStorage.clear();
        },
        loginError () {
            this.errorMessage = 'Invalid Credentials';
        }
    }
};
</script>
<style scoped>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.logo-img {
    width: 16.75rem;
}
</style>
